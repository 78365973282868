import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  BookingDTO,
} from '../../../../../types';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import Link from '../../../Components/Link';
import {
  ActionsButtonsDataHooks,
  ViewMoreDetailsDataHooks,
} from '../../datahooks';

export interface ViewMoreDetailsProps extends ActionButtonProps {
  booking: BookingDTO;
}

export const ViewMoreDetails: React.FC<ViewMoreDetailsProps> = ({
  booking,
  type,
}) => {
  const { actions } = useApplicationContext();
  const { t } = useTranslation();
  let content;

  if (type === ActionButtonUiTypes.LINK) {
    content = (
      <Link
        data-hook={ActionsButtonsDataHooks.ViewMoreDetailsLink}
        onClick={() => {
          actions.clickOnViewMoreDetails({ booking });
        }}
      >
        {t('app.my-bookings-widget.view-service-details.link.label')}
      </Link>
    );
  }

  return <div data-hook={ViewMoreDetailsDataHooks.Main}>{content}</div>;
};
