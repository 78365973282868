import React, { useEffect } from 'react';
import { BookingPolicyModal } from '@wix/bookings-checkout-common-components';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { BookingsGroup } from '../../../../../../types';
import { CancellationModalDataHooks } from '../../../../../../components/MyBookings/Widget/datahooks';
import { classes } from './PolicyModal.st.css';
import { useApplicationContext } from '../../../../../hooks/useApplicationContext';

export interface BookingPolicyModalContentProps {
  group: BookingsGroup;
  onClose: () => void;
}

export const BookingPolicyModalContent: React.FC<
  BookingPolicyModalContentProps
> = ({ group, onClose }) => {
  const { actions, bookingsPolicySnapshots } = useApplicationContext();
  const { t } = useTranslation();
  const id = group.groupId || group.bookings[0].bookingId;
  const policies = bookingsPolicySnapshots?.[id] || [];
  const { isMobile } = useEnvironment();

  useEffect(() => {
    if (!policies.length) {
      actions.getPolicySnapshot({ group });
    }
  }, []);

  const handleCloseButtonClick = () => {
    onClose();
  };

  return (
    <div className={classes.root}>
      <BookingPolicyModal
        dataHook={CancellationModalDataHooks.BookingPolicy}
        className={classes.modal}
        modalTitle={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.title',
          {
            numOfPolicies: policies.length,
          },
        )}
        isOpen
        isMobile={isMobile}
        isLoading={!policies.length}
        policies={policies}
        onClose={handleCloseButtonClick}
        onPrimaryButtonClick={handleCloseButtonClick}
        primaryButtonText={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.label',
        )}
      />
    </div>
  );
};
